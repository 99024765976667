import * as C from 'app/utils/constants';

export interface WidgetConfig {
  id: number;
}

export interface ChartConfig extends WidgetConfig {
  name: string;
  description: string;
  type: 'chart';
}

export interface MultifeatureAnalysisConfig extends WidgetConfig {
  featureIds: string[];
  startDate: string;
  endDate: string;
  statType: 'mean' | 'sum' | 'area_count';
  layerKey: string;
  timeAggregation: 'monthly' | 'yearly';
  name: string;
  description: string;
  type: 'multifeature';
}

export const DASHBOARD_CONFIGS: {
  [organizationId: string]: (MultifeatureAnalysisConfig | ChartConfig)[];
} = {
  [C.INTERNAL_ORGANIZATION_ID]: [
    {
      type: 'multifeature',
      id: 0,
      featureIds: [
        '114301851',
        '115286383',
        '115286381',
        '115286382',
        '115403026',
        '115400105',
        '115375197',
        '115373468',
        '115420703',
      ],

      startDate: new Date('2025-01-01').toISOString(),
      endDate: new Date('2025-12-31').toISOString(),
      statType: 'mean',
      layerKey: 'S2_NDVI',
      timeAggregation: 'monthly',
      name: 'Vegetation vigor 2025',
      description: 'Average veg vigor across our really important project for 2025.',
    },
    {
      type: 'multifeature',
      id: 1,
      featureIds: [
        '114301851',
        '115286383',
        '115286381',
        '115286382',
        '115403026',
        '115400105',
        '115375197',
        '115373468',
        '115420703',
      ],

      startDate: new Date('2024-01-01').toISOString(),
      endDate: new Date('2024-12-31').toISOString(),
      statType: 'mean',
      layerKey: 'S2_NDVI',
      timeAggregation: 'monthly',
      name: 'Veg vigor 2024',
      description: 'Average vegetation for 2024.',
    },
    {
      type: 'multifeature',
      id: 2,
      featureIds: [
        '114301851',
        '115286383',
        '115286381',
        '115286382',
        '115403026',
        '115400105',
        '115375197',
        '115373468',
        '115420703',
      ],

      startDate: new Date('2023-01-01').toISOString(),
      endDate: new Date('2023-12-31').toISOString(),
      statType: 'mean',
      layerKey: 'S2_NDVI',
      timeAggregation: 'monthly',
      name: 'Veg vigor 2023',
      description: 'Average vegetation for 2023.',
    },
  ],
};
