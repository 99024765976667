import {History} from 'history';
import * as I from 'immutable';
import React from 'react';

import {WithMonitoringFeatures} from 'app/pages/ProjectDashboardSwitch/view';
import {LoggedInUserActions} from 'app/providers/AuthProvider';
import {useProjects} from 'app/providers/ProjectsProvider';
import {getFeatureCollectionsByType} from 'app/utils/featureCollectionUtils';
import {useUserInfo} from 'app/utils/featureFlags';

import AppNav from '../AppNav';
import {NavSidebarContainer} from '../NavSidebar';
import cs from './Dashboard.styl';
import {DASHBOARD_CONFIGS} from './dashboardConfigs';
import {MultiFeatureAnalysisWidget} from './MultiFeatureAnalysisWidget/MultiFeatureAnalysisWidget';
import {formattedFeatureNames} from './utils/chartUtils';

type DashboardPageProps = {
  loggedInUserActions: LoggedInUserActions;
  history: History;
};

const DEMO_PROJECT_ID = '4bb23c8e-f7d7-4569-946d-2fcce7aad2fc';

const Dashboard: React.FC<DashboardPageProps> = ({history, loggedInUserActions}) => {
  const [organization, profile] = useUserInfo();

  // TODO(arohan): This is tying features that we load to a specific project, which we don't want
  // for now, it's here so we can build using real feature data, but we need to port this to work
  // efficiently with a list of feature IDs.
  // We should be able to use the /search features endpoint for this, and/or write an endpoint
  // where we just pass back a list of featureIds and get features.
  const [projects, featureCollections, projectsActions] = useProjects();

  // Using a specific project for now
  const targetProject = projects?.find((p) => p!.get('id') === DEMO_PROJECT_ID) || null;

  const [primaryFeatureCollection] = targetProject
    ? getFeatureCollectionsByType(targetProject, featureCollections)
    : [null];

  // Placeholder
  const selectedFeatureIdParams = I.Set<string>();

  const refreshFeatureCollection = (featureCollectionId: number) => {
    if (projectsActions) {
      return projectsActions.refreshFeatureCollection(featureCollectionId);
    }
  };

  return (
    <>
      <AppNav
        history={history}
        organization={organization}
        profile={profile}
        selectedProject={null}
        loggedInUserActions={loggedInUserActions}
      />
      <NavSidebarContainer history={history}>
        <div className={cs.dashboardContainer} data-testid="dashboard-container">
          <h1>Dashboard</h1>

          {targetProject && primaryFeatureCollection && (
            <WithMonitoringFeatures
              selectedProject={targetProject}
              selectedFeatureCollection={primaryFeatureCollection}
              selectedFeatureIdParams={selectedFeatureIdParams}
              refreshFeatureCollection={refreshFeatureCollection}
            >
              {({features}) => {
                const featureNames = formattedFeatureNames(features.toJS());
                const charts = DASHBOARD_CONFIGS[organization.get('id')];
                return (
                  <div className={cs.widgetContainer}>
                    {charts.map(
                      (chart) =>
                        chart.type === 'multifeature' ? (
                          <MultiFeatureAnalysisWidget
                            key={chart.id}
                            widgetConfig={chart}
                            featureNames={featureNames}
                          />
                        ) : null //TODO: implement other chart types
                    )}
                  </div>
                );
              }}
            </WithMonitoringFeatures>
          )}
        </div>
      </NavSidebarContainer>
    </>
  );
};

export default Dashboard;
