import {ChartData} from 'chart.js';
import moment from 'moment';

import {getAreaColor, makePointColorCallback} from 'app/components/AnalyzePolygonChart/SimpleChart';
import {ApiFeature} from 'app/modules/Remote/Feature/types';
import {DataLayerInfo, LayerInfo} from 'app/utils/layers';

import {MultifeatureAnalysisConfig} from '../dashboardConfigs';
import {FeatureStatsApiResponse} from '../hooks/useAggregatedFeatureStats';

export type MultiFeatureScatterDataPoint = {x: number; y: number; date: Date; featureId: string};

const makeMultiFeatureDataPoints = (
  featureStats: {[date: string]: number},
  featureId: string,
  timeAggregation: MultifeatureAnalysisConfig['timeAggregation']
): MultiFeatureScatterDataPoint[] => {
  if (!featureStats) {
    return [];
  }
  return Object.entries(featureStats)
    .map(([date, value]) => {
      // Ensure the date is always the first day of the aggregation period
      // This is important for the data points on the graph to appear correctly
      // with the aggregation period labels
      // HACK: Adding 1 day to the date to avoid timezone issues
      // This will break if the date we get is the last day of the aggregation period
      // but this is temporary fix until we can figure out the UTC vs local timezone issue
      const firstDayOfMonth = moment(date)
        .add(1, 'day')
        .startOf(timeAggregation === 'monthly' ? 'month' : 'year')
        .toDate();
      return {
        x: firstDayOfMonth.getTime(),
        y: value,
        featureId: featureId,
        date: firstDayOfMonth,
      };
    })
    .sort((a, b) => a.x - b.x);
};

/**Used for taking a response to the /statistics endpoint, and converting
 * it into one dataset per feature
 */
export const makeMultiFeatureDatasets = (
  statsByFeature: FeatureStatsApiResponse,
  selectedFeatureIds: string[],
  timeAggregation: MultifeatureAnalysisConfig['timeAggregation'],
  formattedFeatureNames: {[featureId: string]: string}
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  return Object.entries(statsByFeature).map(([featureId, featureStats]) => {
    const lineColor = hashStringToColor(featureId);
    const lineColorWithOpacity = lineColor.replace('rgb', 'rgba').replace(')', ', 0.1)');
    const featureName = formattedFeatureNames[featureId] || featureId;
    const isSelected = selectedFeatureIds.includes(featureId);
    return {
      data: makeMultiFeatureDataPoints(featureStats, featureId, timeAggregation),
      label: featureName,
      borderColor: isSelected ? lineColor : lineColorWithOpacity,
      backgroundColor: isSelected ? lineColor : lineColorWithOpacity,
      pointBorderColor: isSelected ? lineColor : lineColorWithOpacity,
      pointHoverBackgroundColor: lineColor,
      pointHoverBorderColor: lineColor,
      hoverBorderColor: lineColor,
      fill: false, // To avoid filling the area under the line
    };
  });
};

/**Used for taking a response to the /statistics endpoint, and collapsing
 * it down to a single timeseries averaged across all points.
 */
export const calculateTrendLine = (
  featureStats?: FeatureStatsApiResponse
): {[date: string]: number} => {
  if (!featureStats) {
    return {};
  }
  // Get all unique timestamps across all features
  const allTimestamps = getAllSortedTimestamps(featureStats);

  // Calculate average for each timestamp
  const averageValueByDate: {[date: string]: number} = {};
  allTimestamps.forEach((timestamp) => {
    let sum = 0;
    let count = 0;

    // Sum up all values for this timestamp
    Object.values(featureStats).forEach((timeseriesData) => {
      const value = timeseriesData[timestamp];
      if (value !== undefined) {
        sum += value;
        count++;
      }
    });

    // Calculate and store average
    averageValueByDate[timestamp] = count > 0 ? sum / count : 0;
  });
  return averageValueByDate;
};

export const makeTrendLineChartDatasets = (
  trendLine: {[date: string]: number},
  layer: DataLayerInfo,
  showFeatureTable: boolean,
  timeAggregation: MultifeatureAnalysisConfig['timeAggregation']
): ChartData<'line', MultiFeatureScatterDataPoint[]>['datasets'] => {
  const pointColorCallback = makePointColorCallback(
    layer.gradientStops,
    layer.dataRange,
    'average',
    layer.key
  );

  const averageTrendLineDataset = {
    data: makeMultiFeatureDataPoints(trendLine, 'average', timeAggregation),
    label: 'Average',
    ...(!showFeatureTable
      ? {
          borderColor: (context) => pointColorCallback(context.parsed?.y),
          backgroundColor: (context) => pointColorCallback(context.parsed?.y),
          pointBorderColor: (context) => pointColorCallback(context.parsed.y),
          pointHoverBackgroundColor: (context) => pointColorCallback(context.parsed.y),
          pointHoverBorderColor: (context) => pointColorCallback(context.parsed.y),
          fill: {
            target: 'start',
            above: getAreaColor(layer.gradientStops, layer.dataRange, 'average', layer.key),
          },
        }
      : {
          borderColor: 'black',
          backgroundColor: 'black',
          pointBorderColor: 'black',
          pointHoverBackgroundColor: 'black',
          pointHoverBorderColor: 'black',
        }),
  };
  return [averageTrendLineDataset];
};

/**Function to have stable colors for each feature for now. */
function hashStringToColor(str: string): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const r = Math.abs((hash & 0xff0000) >> 16);
  const g = Math.abs((hash & 0x00ff00) >> 8);
  const b = Math.abs(hash & 0x0000ff);
  return `rgb(${r}, ${g}, ${b})`;
}

export const getAllSortedTimestamps = (featureStats?: FeatureStatsApiResponse): string[] => {
  if (!featureStats) {
    return [];
  }
  const allTimestamps = new Set<string>();
  Object.values(featureStats).forEach((timeseriesData) => {
    Object.keys(timeseriesData).forEach((timestamp) => {
      allTimestamps.add(timestamp);
    });
  });
  return Array.from(allTimestamps).sort();
};

// Function to generate and return the chartjs canvas with title, description and layer info
// scaleFactor will allow us to scale the canvas up or down for better legibility, ie. 2 = 2x the size
export function formatChartCanvasForDownload(
  originalCanvas: HTMLCanvasElement,
  widgetConfig: MultifeatureAnalysisConfig,
  layer: LayerInfo,
  scaleFactor: number = 1
): HTMLCanvasElement {
  // Create a new canvas with dimensions scaled by the scaleFactor
  // add 100px to the height for the title and description
  const canvas = document.createElement('canvas');
  canvas.width = originalCanvas.width * scaleFactor;
  canvas.height = (originalCanvas.height + 100) * scaleFactor;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Failed to get canvas context');
  }

  // Apply the scaleFactor for everything
  ctx.scale(scaleFactor, scaleFactor);

  // Fill with white background first, otherwise there is no background
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width / scaleFactor, canvas.height / scaleFactor);

  // Add title
  ctx.fillStyle = '#000000';
  ctx.font = 'bold 16px sans-serif';
  ctx.textAlign = 'center';
  ctx.fillText(widgetConfig.name, canvas.width / (2 * scaleFactor), 25);

  // Add description
  ctx.font = '12px sans-serif';
  ctx.fillText(
    widgetConfig.description.length > 100
      ? widgetConfig.description.substring(0, 100) + '...'
      : widgetConfig.description,
    canvas.width / (2 * scaleFactor),
    50
  );

  // Add layer and feature count information
  ctx.font = '12px sans-serif';
  ctx.fillText(
    `${layer.display} | ${widgetConfig.featureIds.length} properties`,
    canvas.width / (2 * scaleFactor),
    75
  );

  // Draw the original canvas content below the text
  ctx.drawImage(originalCanvas, 0, 100);

  return canvas;
}
export const formatAggregatedDate = (
  date: string,
  aggregation: MultifeatureAnalysisConfig['timeAggregation']
) => {
  if (aggregation === 'monthly') {
    return moment.utc(date).format('MMM YYYY');
  }
  return moment.utc(date).format('YYYY');
};

export function formattedFeatureNames(features: ApiFeature[]): {[featureId: string]: string} {
  return features.reduce(
    (acc, feature) => {
      if (!feature) return acc;

      acc[feature.id] = feature.properties.multiFeaturePartName
        ? `${feature.properties.name} - ${feature.properties.multiFeaturePartName}`
        : feature.properties.name;

      return acc;
    },
    {} as {[featureId: string]: string}
  );
}
