import {UseQueryResult, useQuery} from '@tanstack/react-query';

import api from 'app/modules/Remote/api';

import {MultifeatureAnalysisConfig} from '../dashboardConfigs';
// Example: { "feature1": { "2023-01-01T00:00:00Z": 10.5, "2023-02-01T00:00:00Z": 11.2 }, ... }
export type FeatureStatsApiResponse = {[featureId: string]: {[date: string]: number}};

export function useMultiFeatureAnalysisStats({
  featureIds,
  layerKey,
  startDate,
  endDate,
  statType = 'mean',
  timeAggregation = 'monthly',
}: MultifeatureAnalysisConfig): UseQueryResult<FeatureStatsApiResponse, Error> {
  // Stable query key including all relevant API parameters
  const queryKey = [
    'aggregatedFeatureStats', // New key base reflects the hook's purpose
    featureIds?.sort().join('-') || 'no-features',
    layerKey,
    startDate,
    endDate,
    statType,
    timeAggregation,
  ];

  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await api.dashboardStats().get({
        layerKey,
        startDate,
        endDate,
        statType,
        timeAggregation,
        featureIds,
      });

      return response.get('data').toJS() as FeatureStatsApiResponse;
    },
  });
}
