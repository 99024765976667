import * as B from '@blueprintjs/core';
import React from 'react';

import {DashboardWidget} from 'app/components/Dashboard/DashboardWidget';
import {useMultiFeatureAnalysisStats} from 'app/components/Dashboard/hooks/useAggregatedFeatureStats';
import {
  calculateTrendLine,
  formatChartCanvasForDownload,
  getAllSortedTimestamps,
  makeMultiFeatureDatasets,
  makeTrendLineChartDatasets,
} from 'app/components/Dashboard/utils/chartUtils';
import {usePushNotification} from 'app/components/Notification';
import {downloadCanvasAsImage} from 'app/utils/domUtils';
import {DataLayerInfo} from 'app/utils/layers';
import {getLayer} from 'app/utils/layerUtils';

import * as cs from './MultiFeatureAnalysis.styl';
import MultiFeatureAnlaysisLineChart from './MultiFeatureAnalysisChart';
import MultiFeatureAnlaysisTable from './MultiFeatureAnalysisTable';
import {MultifeatureAnalysisConfig} from '../dashboardConfigs';

export const MultiFeatureAnalysisWidget: React.FC<{
  widgetConfig: MultifeatureAnalysisConfig;
  featureNames: {[featureId: string]: string};
}> = ({widgetConfig, featureNames}) => {
  const {featureIds, layerKey, timeAggregation} = widgetConfig;
  const chartRef = React.useRef<HTMLDivElement>(null);
  const pushNotification = usePushNotification();

  //TODO: This is where we will control the current set of currently displayed datasets.
  //We will need to pass the setter logic here to both the chart and the table, so that
  //we can affect the chart by interacting with the table (and vice versa?)
  const [showAggregateTrend, setShowAggregateTrend] = React.useState(true);
  const [displayFeatureIds, setDisplayFeatureIds] = React.useState<string[]>([]);
  const [showFeatureTable, setShowFeatureTable] = React.useState(false);

  const {data, isLoading, error, isFetching} = useMultiFeatureAnalysisStats(widgetConfig);

  const [multiFeatureDatasets, setMultiFeatureDatasets] = React.useState(
    data ? makeMultiFeatureDatasets(data, displayFeatureIds, timeAggregation, featureNames) : null
  );

  React.useEffect(() => {
    if (displayFeatureIds && data) {
      const newDatasets = makeMultiFeatureDatasets(
        data,
        displayFeatureIds,
        timeAggregation,
        featureNames
      );
      setMultiFeatureDatasets(newDatasets);
    }
  }, [data, displayFeatureIds, featureNames, timeAggregation]);

  const layer = getLayer(layerKey);
  const allDates = getAllSortedTimestamps(data);

  // TODO: the type of aggregation we do here will depend on the dataset. for category
  // we'll want to sum area of each category, rather than means.
  const trendLine = React.useMemo(() => calculateTrendLine(data), [data]);
  const trendLineDatasets = React.useMemo(
    () =>
      makeTrendLineChartDatasets(
        trendLine,
        layer as DataLayerInfo,
        showFeatureTable,
        timeAggregation
      ),
    [trendLine, layer, showFeatureTable, timeAggregation]
  );

  const pushDownloadError = () => {
    pushNotification({
      message: 'Error downloading chart',
      options: {
        intent: B.Intent.DANGER,
      },
    });
  };

  const handleDownloadPng = () => {
    if (!chartRef.current) return;

    // Find the canvas element inside the chart
    const originalCanvas = chartRef.current.querySelector('canvas');
    if (!originalCanvas) {
      pushDownloadError();
      console.error('No canvas element found in the chart');
      return;
    }

    try {
      const canvas = formatChartCanvasForDownload(originalCanvas, widgetConfig, layer, 2);

      const filename = `${widgetConfig.name.replace(/\s+/g, '_')}_chart.png`; // regex replace spaces with underscores
      downloadCanvasAsImage(canvas, filename);
    } catch (error) {
      pushDownloadError();
      console.error('Error downloading chart as PNG:', error);
    }
  };

  return (
    <DashboardWidget
      title={widgetConfig.name}
      size={showFeatureTable ? 'large' : 'small'}
      controls={
        <div className={cs.widgetControlsContainer}>
          <B.Tooltip content={showFeatureTable ? 'Hide details' : 'Show details'}>
            <B.AnchorButton
              icon={showFeatureTable ? 'minimize' : 'maximize'}
              minimal
              small
              onClick={() => {
                setShowFeatureTable((prev) => !prev);
                setDisplayFeatureIds(showFeatureTable ? [] : featureIds.slice(0, 4));
              }}
            />
          </B.Tooltip>
          <B.Popover
            content={
              <B.Menu>
                <B.MenuItem
                  icon="download"
                  text="Download chart as PNG"
                  onClick={handleDownloadPng}
                />
              </B.Menu>
            }
            placement="bottom-end"
            minimal
          >
            <B.Button icon="more" minimal small />
          </B.Popover>
        </div>
      }
    >
      {/* Basic loading/error display */}
      {isLoading && <div>Loading...</div>}
      {isFetching && <div>Fetching...</div>}
      {error && <div>Error: {error.message}</div>}

      <div>
        <p>
          <span className={cs.widgetLabel}>Description:</span> {widgetConfig.description}
        </p>
        {/* Summary of data displayed in the chart */}
        <p>
          <span className={cs.widgetLabel}>Chart: </span>{' '}
          {`${layer.display} | ${widgetConfig.featureIds.length} properties`}
        </p>
      </div>

      <div ref={chartRef}>
        {multiFeatureDatasets && featureIds && (
          <MultiFeatureAnlaysisLineChart
            widgetConfig={widgetConfig}
            datasets={
              !showFeatureTable
                ? trendLineDatasets
                : showAggregateTrend
                  ? [...trendLineDatasets, ...multiFeatureDatasets]
                  : multiFeatureDatasets
            }
            hideLegend
            isExpanded={showFeatureTable}
          />
        )}
      </div>

      {featureIds && showFeatureTable && data && (
        <MultiFeatureAnlaysisTable
          displayFeatureIds={displayFeatureIds}
          allFeatureIds={featureIds}
          setDisplayFeatureIds={setDisplayFeatureIds}
          featureStats={data}
          featureNames={featureNames}
          allDates={allDates}
          setShowAggregateTrend={setShowAggregateTrend}
          showAggregateTrend={showAggregateTrend}
          widgetConfig={widgetConfig}
        />
      )}
    </DashboardWidget>
  );
};
